@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@import "styles/main";
@import "styles/colors";
@import "styles/typography";
@import "styles/table";

html, body, #root {
	margin: 0;
	height: 100%;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-text-size-adjust: none;

	* {
		box-sizing: border-box;
	}

	&::backdrop {
		background-color: white;
	}
}

body {
	margin: 0;
	font-family: 'Roboto',
	sans-serif;
}

button {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
