.editable-content-label {
	display: flex;
	align-items: center;

	& > span {
		cursor: pointer;
		height: 18px;
		margin: -2px 0;

		& > svg {
			margin-left: 10px;
		}
	}
}
