@import "src/styles/typography";

.project-export-errors-modal {
	&_contact-msg {
		@extend .text-default;
		margin-bottom: 20px;
	}

	&_error-list {
		margin-bottom: 20px;
	}
}
