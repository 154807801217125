@import "src/styles/colors";

.spinner {
	background-color: white;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
