@import "src/styles/typography";

.tree-row {
	&_header {
		@extend .heading2;
		font-weight: 500;

		display: flex;
		align-items: center;
		padding: 4px 0 10px;

		& > * {
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}
		}

		&_toggle-arrow {
			display: flex;
			margin-right: 0;

			svg {
				transform-origin: center center;
				transform: rotate(-90deg);
				transition: transform 0.25s ease;
			}

			&.is-open {
				svg {
					transform: rotate(0);
				}
			}
		}
	}

	&_content {
		padding-left: 30px;
	}
}