@import "src/styles/typography";
@import "src/styles/colors";
@import "src/styles/mixins";

$background-color: #f7f8f9;

.panel-item {
	font-size: 12px;
	width: 100%;
	$self: &;
	background-color: $background-color;

	&_bar {
		display: flex;
		min-width: 0;
		align-items: center;
		height: 34px;
		cursor: pointer;
		padding: 4px 10px 4px 5px;

		border: 2px solid transparent;

		@include hover-media {
			&.is-hover, &:hover {
				background-color: $highlightPanelColour;
			}
		}

		&_icon {
			margin-right: 5px;
			min-height: 25px;
			min-width: 25px;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				background-color: rgba(255, 255, 255, 0.5);
			}

			& > svg {
				transition: transform 0.25s ease;
				transform: rotateZ(180deg);
			}
		}

		&_content {
			display: flex;
			min-width: 0;
			width: 100%;
			flex: 1 auto;

			padding-right: 10px;
			line-height: 26px;
			height: 100%;

			&_symbol-preview {
				position: relative;
				width: 16px;
				height: 16px;
				top: 3px;
				margin-right: 6px;
			}

			&_start-icon svg {
				margin-right: 5px;
				position: relative;
				// Vertical align standard 16x16 icon.
				top: 3px;
			}

			&_label {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&_pin-icon {
				display: flex;
				align-items: center;

				svg {
					margin-left: 3px;
					position: relative;
					// Vertical align standard 16x16 icon.
					top: 1px;
				}
			}

			&_spacer {
				flex: 1 auto;
			}
		}

		&_props {
			flex-grow: 0;
			margin-right: 10px;
		}

		&_actions {
			display: flex;
			align-items: center;

			& > * {
				cursor: pointer;

				&.disabled {
					cursor: not-allowed;
				}

				margin-right: 2px;

				&:last-child {
					margin-right: 0;

					& .dropdown_button {
						width: 15px;
						height: 15px;
						padding: 0;

						& > svg {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
		}
	}

	&_content {
		overflow: hidden;
		visibility: hidden;
		height: 0;
	}

	&.is-active {
		#{$self}_bar {
			background: $simproBlue;
			color: white;
			font-weight: bold;

			@include hover-media {
				&.is-hover, &:hover {
					background: darken($simproBlue, 8);
				}
			}
		}
	}

	&.is-expanded {
		#{ $self }_content {
			height: auto;
			overflow: visible;
			visibility: visible;
		}

		#{ $self }_bar {
			&_icon {
				& > svg {
					transform: rotateZ(270deg);
				}
			}
		}
	}
}
