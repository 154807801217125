@import "src/styles/colors";

.project-details-header {
	width: 100%;
	font-size: 14px;
	border-bottom: 1px solid lightGray;
	margin-bottom: 5px;

	&_container {
		padding-top: 10px;
		padding-bottom: 18px;
		font-weight: bold;

		&_blue-text {
			font-weight: normal;
			color: $simproBlue;
		}
	}

	&_container > span {
		margin-right: 20px;
	}
}