@import "src/styles/typography";
@import "src/styles/colors";

.tabs-tab {
	@extend .text-default;
	color: $simproBlue;
	padding: 12px 23px;
	margin-right: 8px;
	border-radius: 4px 4px 0 0;

	&:hover {
		background-color: $tabHover;
	}

	&.is-active {
		font-weight: 700;
		color: black;
		background: $midDarkGrey;
	}

	&.is-clickable {
		cursor: pointer;
	}

	&.sub-tab {
		font-size: 12px;
		padding: 12px 16px;
		margin-right: 0;
		border-radius: 0;
	}
}