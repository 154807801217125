@import "src/styles/typography";

.editor-header-scale-panel {
	display: flex;
	align-items: center;
	height: 36px;

	&_input-label {
		font-weight: 700;
		font-size: 14px;
		padding-left: 5px;
	}

	.text-input {
		input {
			padding: 3.5px 10px;
			width: 100px;
		}
	}

	.number-input {

		span {
			margin-right: 10px;
		}

		input {
			width: 100px;
			padding: 3.5px 10px;
			margin: 0 0;
		}
	}

	& > * {
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}

	&.imperial {
		.inputs-label {
			@extend .text-default;
			font-weight: 700;
		}

		input {
			width: 80px;
		}
	}
}