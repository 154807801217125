@import "src/styles/colors";
@import "src/styles/typography";

.assign-to-new-cost-center-modal {
	min-width: 400px;
	min-height: 180px;


	&_container {
		margin: 20px 20px 0;
		padding-bottom: 20px;

		&_field {
			display: flex;
			flex-direction: column;

			&_select {
				@extend .text-default;
				border: 1px solid $tabContentBorderColour;
				width: 100%;
			}

			&_input-label {
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 8px;
			}

			margin-bottom: 20px;
		}
	}
}