@import "src/styles/colors";

.btn {
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	padding: 4px 16px;
	border-radius: 3px;
	border: 1px solid transparent;
	outline: none;
	cursor: pointer;
	transition: background-color 0.25s ease;
	font-family: $defaultFontFamily;
	position: relative;

	&.plus-icon {
		padding-left: 25px;
	}

	&_plus-icon_container {
		position: absolute;
		left: 6px;
		top: 5px;
	}

	&.dropdown-arrow {
		padding-right: 40px;
	}

	> svg {
		vertical-align: bottom;
		height: 14px;
		width: 14px;
	}
}

.btnGreen {
	@extend .btn;
	background-color: $green;
	border-color: $green;
	color: white;

	&.disabled {
		background-color: $lightGray;
		border-color: $lightGray;
		color: $lighterText;
		cursor: not-allowed;

		&:hover {
			background-color: $lightGray;
		}
	}

	&:hover {
		background-color: darken($green, 5);
	}

	&:focus {
		border: 1px solid darken($green, 20%);
		background-color: darken(#40AE23, 5%);
	}
}

.btnGray {
	@extend .btn;
	background-color: $midDarkGrey;
	border-color: $midDarkGrey;
	color: black;

	&.disabled {
		background-color: $lightGray;
		border-color: $lightGray;
		color: $lighterText;
		cursor: not-allowed;

		&:hover {
			background-color: $lightGray;
		}
	}

	&:hover {
		background-color: darken($midDarkGrey, 5);
	}

	&:focus {
		border: 1px solid darken($midDarkGrey, 20%);
		background-color: darken($midDarkGrey, 5%);
	}
}

.btnBlue {
	@extend .btn;
	background-color: $simproBlue;
	border-color: $simproBlue;
	color: white;

	&.disabled {
		background-color: $lightGray;
		border-color: $lightGray;
		color: $lighterText;
		cursor: not-allowed;

		&:hover {
			background-color: $lightGray;
		}
	}

	&:hover {
		background-color: darken($simproBlue, 5);
	}

	&:focus {
		border: 1px solid darken($lightBlue, 20%);
		background-color: darken($lightBlue, 5%);
	}
}

.btnSec--secondaryButton {
	@extend .btn;
	$self: &;
	background-color: white;
	border-color: $simproBlue;
	color: $simproBlue;

	&.disabled {
		background-color: white;
		border-color: $midGray;
		color: $lighterText;
		cursor: not-allowed;

		&:hover {
			background-color: white;
		}
	}

	&:hover {
		background-color: darken(white, 5);
	}

	&:focus {
		border: 1px solid darken($simproBlue, 20%);
		background-color: darken(white, 5%);
	}
}

.btnText {
	@extend .btn;
	color: $simproBlue;
	background-color: transparent;

	&.disabled {
		color: $lighterText;
		cursor: not-allowed;

		&:hover {
			background-color: white;
		}
	}

	&:hover {
		background-color: darken(white, 5);
	}

	&:focus {
		background-color: darken(white, 5);
	}
}