.icon-as-built {
	position: relative;

	&_signature {
		position: absolute;
		transform: rotate(30deg);
		color: red;
		font-weight: bold;
		font-size: 11px;
		white-space: nowrap;
		width: 24px;
		height: 24px;
		right: 15px;
		bottom: 2px;
	}
}