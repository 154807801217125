@import "src/styles/colors";

.plan-upload-editor {
	flex: 1;
	height: 100%;
	display: grid;
	overflow: auto;
	overflow-y: hidden;
	grid-template-rows: auto 1fr;

	&_host {
		order: 1;
		flex: 1;
		position: relative;
		background-color: $lightGray;
		touch-action: none;

		&_scroll-container {
			cursor: default;
			overflow: auto;
			position: absolute;

			&_stage {
				overflow: hidden;
				position: relative;
			}
		}
	}
}
