@import "src/styles/typography";
@import "src/styles/colors";

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	overflow: hidden;
	@extend .link;
	user-select: none;

	&:hover {
		text-decoration: none;
		color: $simproBlue;
	}

	&.disabled {
		cursor: not-allowed;
	}

	&_link {
		padding: 4px;
		margin: 0 2px;
		border: 1px solid transparent;

		&.active {
			border: 1px solid $tabContentBorderColour;
		}

		&:hover {
			border: 1px solid $tabContentBorderColour;
		}
	}

	&_placeholder {
		height: 46px;
	}
}