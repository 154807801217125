@import "src/styles/colors";
@import "src/styles/typography";

.dropdown-item {
	@extend .text-default;
	min-width: 170px;
	white-space: nowrap;
	padding: 8px 12px;
	text-align: left;
	color: $baseText;
	cursor: pointer;

	svg path {
		fill: $baseText;
	}

	&:hover {
		background-color: $simproBlue;
		color: white;

		svg path {
			fill: white;
		}
	}

	&.is-disabled {
		cursor: not-allowed;
		color: $lighterText;

		&:hover {
			background-color: $simproBlue;
			color: white;
		}
	}
}