@import "src/styles/forms";
@import "src/styles/typography";

.number-input {
	@extend .text-default;
	font-weight: 700;

	& input {
		@extend .form-field;
		margin-bottom: 30px;
		margin-top: 8px;
		border: 1px solid #ccc;
		padding: 8px 10px;
		border-radius: 4px;
		width: 100%;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}
	}
}
