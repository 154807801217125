@import "src/styles/typography";
@import "src/styles/colors";

.editable-cell {
	display: flex;
	align-items: center;
	margin: -10px 0;

	.number-input input, &_select {
		margin-top: 0;
		margin-bottom: 0;
		padding: 2px 6px;
		width: 60px;
		height: 21px;
		background: white;
	}

	&_action-button {
		width: 20px;
		height: 20px;
		border-radius: 10px;
		margin-left: 3px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;

		&.submit {
			background-color: $green;
		}

		&.cancel {
			background-color: $red;
		}
	}
}
