@import "src/styles/colors";

.icon-arc-tool {
	width: 32px;
	height: 32px;
	border-radius: 4px;

	& > svg > path {
		stroke: black;
	}


	&:hover {
		background-color: $tabHover;
		cursor: pointer;
	}

	&.is-disabled {
		cursor: not-allowed;

		& > svg > path {
			stroke: gray;
		}
	}

	&.is-active {
		background-color: $simproBlue;

		& > svg > path {
			stroke: white;
		}

		&:hover {
			background-color: $simproBlue;
			cursor: pointer;
		}
	}
}