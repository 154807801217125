.page-header-buttons {
	margin-left: auto;

	> * {
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}
}