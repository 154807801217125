@import "src/styles/colors";

.edit-text-container {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	textarea {
		position: absolute;
		border: 1px dashed $baseText;
		border-radius: 4px;
		padding: 5px;
		margin: 0;
		overflow: hidden;
		background: none;
		outline: none;
		resize: none;
		line-height: 1;
	}

	.reference {
		display: inline-block;
		white-space: pre-wrap;
		word-wrap: break-word;
		line-height: 1;
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
	}

	.accept-button {
		position: absolute;
		background-color: $green;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		display: inline-block;
		padding: 4px;
		cursor: pointer;
	}
}