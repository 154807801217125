@import "src/styles/typography";
@import "src/styles/colors";

.zoom-controls {
	@extend .text-default;
	display: flex;
	align-items: center;
	user-select: none;

	&_scale {
		cursor: pointer;
		width: 35px;
		text-align: center;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: $tabHover;
		}
	}

	svg {
		cursor: pointer;
	}
}