@import "src/styles/colors";


.template-pages-preview-modal {
	margin-left: 24px;
	margin-right: 24px;
	max-height: 650px;

	&_header {
		font-size: 18px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;


		&_icon {
			cursor: pointer;
		}
	}

	&_content {
		margin-top: 27px;
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
		overflow: hidden;


		&_filename {
			font-size: 14px;
			margin-bottom: 24px;
		}

		&_dpi-input-container {
			margin-bottom: 15px;
			width: 150px;
			display: flex;
			flex-direction: column;

			& > label {
				& > input {
					width: 100%;
				}
			}

			&_label {
				font-weight: bold;
				margin-bottom: 5px;
			}
		}

		&_line {
			border: 1px solid $midGray;
		}

		&_preview {
			overflow-y: auto;
			margin-top: 14px;
			margin-bottom: 24px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;

			&_container {
				margin-top: 10px;
				height: fit-content;
				width: fit-content;
				border: 3px solid transparent;

				&:hover {
					border-color: $midGray;
				}

				&.multi {
					flex-basis: 45%;
				}

				&.hidden {
					display: none;
				}

				&.selected {
					border-color: $lightSimproBlue;

					&:hover {
						border-color: darken($lightSimproBlue, 20);
					}
				}

				&.unselectable {
					border-color: $midGray2;

					&:hover {
						border-color: darken($midGray2, 20);
					}
				}

				&_image {
					display: block;
					max-width: 100%;
					max-height: 300px;
				}
			}
		}

		&_loading {
			font-size: 14px;

			&_spinner {
				justify-content: center;
				display: flex;
			}
		}
	}
}