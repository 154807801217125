.assign-to-new-section-modal {
	min-width: 400px;
	min-height: 170px;

	&_container {
		display: flex;
		flex-direction: column;
		margin: 15px 14px 15px;

		&_label {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 8px;
		}

		&_input {
			width: 100%;
			height: 32px;
		}

		&_footer {
			margin-top: 25px;
		}
	}
}