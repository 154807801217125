@import "src/styles/colors";
@import "src/styles/typography";

.editor-panel {
	$self: &;
	display: flex;

	&_container {
		box-sizing: border-box;
		width: 360px;
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr;

		&_heading {
			@extend .heading2;
			padding: 10px;
			display: flex;
			align-items: center;

			&_label {
				display: flex;
				align-items: center;

				& > svg {
					margin-right: 5px;
				}
			}

			&_spacer {
				flex: 1;
			}

			button {
				margin-left: 5px;
			}
		}

		&_content {
			overflow: auto;

			@media (hover: none) and (pointer: coarse) {
				transform: translate3d(0, 0, 0);
			}

			td .pinned-delete-icon svg {
				position: relative;
				top: 2px;

				&:hover {
					cursor: pointer;
				}
			}

			td .pinned-symbol-name {
				display: flex;
				align-items: center;

				&_icon {
					position: relative;
					width: 16px;
					height: 16px;
					bottom: 2px;
					margin-right: 6px;
				}

				&_label {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&_pinned-icon svg {
					margin-left: 3px;
					position: relative;
					top: 2px;
				}

				&_spacer {
					flex: 1 auto;
				}
			}
		}
	}
}