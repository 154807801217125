@import "src/styles/colors";

.sensitivity-slider-container {
	font-weight: 400;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&_label {
		white-space: nowrap;
	}
}
