@import "src/styles/typography";
@import "src/styles/colors";

.edit-ftm-input {
	margin-bottom: 30px;

	&_select {
		@extend .text-default;
		border: 1px solid $tabContentBorderColour;
		width: 100%;
	}

	&_label {
		@extend .text-default;
		font-weight: 700;
		margin-bottom: 8px;
	}
}
