@import "src/styles/typography";
@import "src/styles/colors";

.create-symbol-group-modal {
	margin-bottom: 30px;

	&_field {
		@extend .text-default;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&_label {
			font-weight: bold;
			margin-bottom: 8px;

			&.secondary {
				color: $lighterText;
			}
		}

		& input {
			width: 100%;
			height: 32px;
			margin-bottom: 20px;
		}

		&.center {
			display: flex;
			align-items: center;
		}
	}

	&_row {
		display: flex;
		justify-content: space-between;

		&_field {
			margin-right: 4px;
			font-weight: bold;
		}

		&_divider {
			flex: 1;
		}

		&_remove-btn svg {
			background-color: $red;
			border-radius: 50%;

			&:hover {
				cursor: pointer;
			}
		}
	}
}