@import "src/styles/colors";


.project-status-presenter {
	display: flex;

	&_dot {
		border-radius: 50%;
		width: 18px;
		height: 18px;
		border: 1px solid $midGray2
	}
}