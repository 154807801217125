@import "src/styles/typography";
@import "src/styles/colors";

.file-preview-modal {

	&_error {
		color: $error;
	}

	&_jpg {
		position: relative;
		flex: 1 1;
		min-height: 0;
		display: flex;
		flex-direction: column;

		&_tools {
			position: absolute;
			width: 120px;
			left: 50%;
			margin-left: -60px;
			text-align: center;
			display: flex;
			justify-content: space-between;
			z-index: 1;
			bottom: 30px;
			background-color: #FFFFFF;
			opacity: 0;
			border: 1px solid black;
			border-radius: 20px;
			transition: opacity 0.3s ease-in-out 0s;

			&.visible {
				opacity: 0.85;
				transition: opacity 0.3s ease-in-out 0s;
			}

			svg {
				height: 32px;
				width: 32px;
			}
		}

		.react-transform-wrapper {
			flex: 1 1;
			min-height: 0;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			display: block;
		}
	}

	&_pdf {
		height: 600px;

		&_tools {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
			border-bottom: 1px solid $midGray;

			&_item {
				padding: 0 2px;

				&_pages-container {
					display: inline-flex;
					align-items: center;
					font-size: 14px;

					&_input {
						width: 50px;
					}
				}
			}
		}

		.viewer {
			height: 100%;
			display: flex;
			flex-direction: column;

			&_toolbar {
				padding: 4px;
				display: flex;
				justify-content: center;
				position: relative;
				box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

				span {
					padding: 6px;
					font-size: 14px;
				}

				button {
					background-color: white;
					border: none;
					border-radius: 4px;
					padding: 6px 10px;

					&:disabled {
						svg path {
							fill: gray;
						}
					}

					&:hover:not(:disabled) {
						background-color: darken(white, 10);
					}
				}

				&_previous-page svg {
					transform: rotate(90deg);
				}

				&_next-page svg {
					transform: rotate(-90deg);
				}
			}

			&_items {
				padding: 20px;
				flex: 1 1;
				overflow: auto;
				display: flex;
				flex-direction: column;
				gap: 20px;

				&_item {
					display: flex;
					box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.1);

					& > img {
						width: 100%;
					}
				}
			}
		}
	}
}
