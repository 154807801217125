@import "src/styles/colors";
@import "src/styles/typography";

.dropzone {
	outline: none;
	padding: 1px;
	border-radius: 3px;
	margin: 0;
	background-color: #fff;
	border: 2px dashed $highlightPanelColour;
	display: flex;
	justify-content: center;
	color: $simproBlue;
	cursor: pointer;

	&.active-drag {
		background-color: $focusColour;
	}

	&.disabled {
		color: $highlightPanelColour;
		cursor: not-allowed;
	}
}
