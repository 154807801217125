@import "src/styles/table";

.drawing-summary-items-table {
	@extend .table;
	margin-bottom: 20px;

	&_container {
		@extend .scrollable;
		height: unset;
		max-height: 60vh;
	}

	thead tr th, tbody tr td {
		padding: 10px 10px;
	}
}
