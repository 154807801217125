@import "src/styles/mixins";

.buttons-group {
	padding: 0 10px;
	display: flex;
	align-items: center;
	white-space: nowrap;

	&.scale-buttons {
		padding-right: 0;
		justify-content: flex-end;
	}

	&.save-finish-buttons {
		padding-left: 5px;
	}

	& > * {
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}
	}

	&:last-child {
		padding-right: 0;
	}
}