@import "src/styles/colors";


.plan-tree-template {

	&:not(:last-child) {
		margin-bottom: 10px;
		border-bottom: 1px solid $midGray;
	}

	&_header {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&_container {
			width: 100%;

			&_title {
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: center;
				margin-right: 12px;

				&_text {
					word-break: break-all;
				}

				& * + * {
					margin-left: 10px;
				}
			}

			&.previewable {
				cursor: pointer;
			}
		}
	}

	&_tree {
		display: flex;
		flex-direction: row;

		&_pages-container {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}