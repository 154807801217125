@import "src/styles/colors";

.selection-tool-box {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	overflow: hidden;

	&_buttons-container {
		display: inline-flex;
		gap: 12px;
		border: 1px solid lightgray;
		flex-direction: row;
		padding: 15px 20px;
		background-color: white;
	}
}