@import "src/styles/typography";
@import "src/styles/colors";

.settings-symbols {
	height: 100%;

	&_title {
		@extend .heading1;
	}

	&_label {
		@extend .text-default;
		font-weight: 700;
		margin-bottom: 8px;
	}

	&_preview {
		display: flex;
		flex-wrap: wrap;
		max-width: 50%;

		&_item {
			border: 1px solid $tabContentBorderColour;
			height: 48px;
			width: 48px;
			margin: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&_breadcrumbs {
		font-size: 18px;

		&_item {
			@extend .link;
			font-size: 18px;
		}

		&_current-item {
			font-weight: bold;
		}
	}

	&_table-row {
		cursor: pointer;

		&.with-actions {
			td {
				padding: 4px 6px;
			}
		}
	}
}