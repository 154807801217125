.project-details-page {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	min-height: 0;

	&_content {
		display: flex;
		flex: 1 1;
	}
}