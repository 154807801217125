@import "src/styles/typography";
@import "src/styles/colors";

.tree-section {
	$self: &;
	margin-bottom: 20px;

	&_header {
		margin-left: 7px;
		display: flex;

		&_container {
			margin-left: 5px;
			font-size: 12px;

			&_label {
				font-weight: bold;
				font-size: 18px;

				&_deleted {
					color: red;
				}
			}
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		height: 0;
		visibility: hidden;
		overflow: hidden;
	}
}