@import "src/styles/colors";

.create-cost-center-modal {
	min-width: 400px;
	min-height: 250px;

	&_container {
		display: flex;
		flex-direction: column;
		margin: 15px 14px 0;

		&_field {
			padding-bottom: 20px;

			&_section-select {
				border: 1px solid $tabContentBorderColour;
				width: 100%;
			}
		}

		&_label {
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 8px;
		}

		&_footer {
			margin-top: 25px;
			margin-bottom: 15px;
		}
	}
}