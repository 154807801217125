%border_box {
	box-sizing: border-box;
}

/*
 * The Standard Bootstrap 4.x Breakpoints
*/
@mixin media($point) {
	@if $point == xs {
		@media screen and (max-width: 575px) {
			@content ;
		}
	}
	@if $point == sm {
		@media screen and (max-width: 768px) {
			@content ;
		}
	}
	@if $point == md {
		@media screen and (max-width: 991px) {
			@content ;
		}
	}
	@if $point == lg {
		@media screen and (max-width: 1199px) {
			@content ;
		}
	}
	@if $point == xl {
		@media screen and (max-width: 1599px) {
			@content ;
		}
	}
	@if $point == xxl {
		@media screen and (min-width: 1600px) {
			@content ;
		}
	}
}

@mixin hover-media {
	@media (hover: hover) {
		@content;
	}
}