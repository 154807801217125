@import "src/styles/colors";

.project-plan-upload-page {
	width: 100%;
	height: 100%;

	&_header {
		&_title {
			display: flex;
			padding-right: 150px;
			align-items: baseline;

			&_editor-label {
				font-weight: bold;
				font-size: 16px;
				margin-right: 6px;
			}

			&_filename-label {
				font-size: 14px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}
	}

	&_body {
		display: flex;
		flex: 1;
		min-height: 0;
		position: relative;

		&_overlay {
			z-index: 1000;
			position: absolute;
			background-color: rgba(255, 255, 255, 0.90);
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			cursor: not-allowed;
		}

		&_panel {
			min-width: 380px;
			flex: 3 3;
			padding: 20px 20px 20px 0;
		}

		&_editor {
			border-left: 1px solid lightGray;
			flex: 7 7;
			min-width: 0;
		}
	}
}