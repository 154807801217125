@import "src/styles/colors";

.confirm-cancel-modal {
	min-height: fit-content;
	width: 500px;

	&_header {
		font-size: 18px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;

		&_icon {
			cursor: pointer;
		}
	}

	&_body {
		margin-top: 10px;
		font-size: 14px;
		margin-bottom: 10px;
	}
}
