@import "src/styles/colors";

.dropdown {
	&.flex {
		display: flex;
	}

	&_button_icon-more {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		padding: 6px;
		border: none;
		margin: 0;
		transition: background-color 0.25s ease;
		max-height: 25px;
		max-width: 25px;

		& > svg {
			min-height: 18px;
			min-width: 18px;
		}

		&:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	&_button_options {
		width: 62px;
		height: 20px;
		border: 1px solid $labelsText;
		background-color: white;
		color: $labelsText;
		font-size: 11px;
		border-radius: 3px;
		padding-right: 60px;
		position: relative;
		cursor: pointer;

		&_arrow-container {
			position: absolute;
			right: 0;
			top: 2px;
			bottom: 0;
			display: flex;
			align-items: center;
			padding: 0 8px;

			&_icon {
				width: 0;
				height: 0;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 6px solid $labelsText;
			}
		}
	}

	&_with_button {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		outline: none;
		cursor: pointer;
		height: 24px;
		width: 24px;
		position: relative;
		background-color: $green;
		border-color: $green;
		color: white;

		&.disabled {
			background-color: $lightGray;
			border-color: $lightGray;
			color: $lighterText;
			cursor: not-allowed;

			&:hover {
				background-color: $lightGray;
			}
		}

		&:hover {
			background-color: darken($green, 5);
		}

		&:focus {
			border: 1px solid darken($green, 20%);
			background-color: darken(#40AE23, 5%);
		}
	}

	&_menu {
		min-width: 80px;
		background-color: white;
		border: 1px solid $midDarkGrey;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

		& > ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		&.inverted-blue {
			.dropdown-item {
				background-color: $simproBlue;
				color: white;

				svg path {
					fill: white;
				}

				&:hover {
					background-color: white;
					color: $baseText;

					svg path {
						fill: $baseText;
					}
				}
			}
		}
	}
}