@import "src/styles/typography";

.drawing-upload-modal {
	&_status {
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
		align-items: center;
		margin-bottom: 15px;

		&_filename {
			word-break: break-word;
		}

		&_icon {
			align-items: center;
			display: flex;
			cursor: pointer;
		}

		& > * + * {
			margin-left: 10px;
		}
	}

	&_title {
		@extend .heading1;
	}

	&_subtitle {
		font-size: 14px;
		margin-bottom: 8px;
	}

	&_files {
		margin-bottom: 20px;
	}

	&_dropzone {
		margin-bottom: 12px;
	}

	&_formats {
		@extend .text-default;
		margin-bottom: 30px;
	}
}