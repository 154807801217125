@import "src/styles/colors";

.symbol-recognition-matches-display-panel {
	font-weight: 500;

	&_approve-all-section {
		display: flex;
		font-size: 14px;
		margin: 8px 12px;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&_advanced-settings-header {
		font-size: 12px;
		background-color: $lightGray;
		display: flex;
		align-items: center;

		&_title {
			margin: 13px 0 13px 12px;
		}
	}

	&_sensitivity-adjustment {
		margin: 24px 24px 0;
		padding-bottom: 25px;
		border-bottom: 1px solid $midGray;
		font-size: 12px;

		&_title {
			margin-bottom: 8px;
		}
	}

	&_manual-approval-section {
		margin: 24px 25px;

		height: 290px;

		&_title {
			font-size: 12px;
		}

		&_statuses {
			margin-top: 16px;
			font-weight: 400;
			font-size: 12px;
			display: flex;

			&_rejected-label {
				color: $red2;
				margin-right: 20px;
			}

			&_approved-label {
				color: $green;
				margin-right: 20px;
			}

			&_remaining-label {
				color: $simproBlue;
			}
		}

		&_panel {
			margin-top: 16px;
			padding-bottom: 16px;
			background-color: white;
			user-select: none;

			&_previews-container {
				padding-top: 24px;
				margin: 24px 24px 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				&_preview {
					width: 40%;
					flex: 1;
					height: 106px;
				}

				& > * + * {
					margin-left: 20px;
				}
			}

			&_navigation {
				display: flex;
				justify-content: center;
				margin-top: 14px;
				font-weight: 700;
				font-size: 14px;
				color: $labelsText;
				align-items: center;

				&_text {
					position: relative;
					bottom: 2px;
				}

				&_left-arrow {
					stroke: $labelsText;
					stroke-width: 1.5px;
					cursor: pointer;
				}

				&_right-arrow {
					transform: rotateY(180deg);
					stroke: $labelsText;
					stroke-width: 1.5px;
					cursor: pointer;
				}
			}

			&_buttons {
				display: flex;
				justify-content: space-between;
				margin: 16px 24px 0;

				& > * + * {
					margin-left: 20px;
				}
			}
		}
	}

	&_footer {
		margin: 16px 24px;
		padding-bottom: 16px;
		display: flex;
		justify-content: space-between;
	}
}
