.assign-to-existing-section-modal {
	min-width: 400px;
	min-height: 180px;

	&_container {
		margin: 20px 20px 0;

		&_field {
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;

			&_label {
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 8px;
			}

			&_select {
				width: 100%;
				height: 32px;
			}
		}
	}
}