@import "src/styles/colors";

.plan-upload-editor-header {
	display: flex;
	flex-direction: column;

	&_main {
		padding: 16px 0 16px 400px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $midDarkGrey;
		position: relative;

		&.has-scale {
			padding-left: 0;
		}
	}

	&_options {
		height: 64px;
		padding: 16px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $midDarkGrey;
	}
}