@import "src/styles/typography";
@import "src/styles/colors";

.settings-main {
	height: 100%;
	position: relative;
	margin-top: 20px;

	&_title {
		@extend .heading1;
	}

	&_select {
		margin-bottom: 20px;

		&_label {
			@extend .text-default;
			margin-bottom: 8px;
		}

		&_selector {
			max-width: 500px;
		}
	}

	&_version {
		@extend .text-small;
		position: absolute;
		bottom: 15px;
		right: 5px;
		color: $lighterText;
	}
}