@import "src/styles/typography";

.react-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
}

.react-modal {
	@extend .text-default;
	display: flex;
	flex-direction: column;
	background-color: white;
	min-width: 400px;
	min-height: 200px;
	width: 600px;
	outline: none;
	box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
	padding: 30px;
}