@import "src/styles/colors";

.plan-upload-no-scale-info-modal {
	padding: 15px;
	display: flex;
	flex-direction: column;
	flex: 1 1;

	&_content {
		flex: 1 1;
		padding: 12px;
		border-radius: 4px;
		background-color: $yellow;
		max-width: 600px;

		&_warning-info {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&_pages {
			padding-top: 8px;
			color: $red2;
			font-size: 12px;
			font-weight: 400;
		}
	}

	.modal-buttons {
		margin-top: 15px;
	}
}