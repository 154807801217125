.table-overlay {
	position: relative;

	&_spinner {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 2;
		background-color: rgba(255, 255, 255, 0.6);
		cursor: not-allowed;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}