.switch-component-icon {
	color: white;
	display: flex;
	font-size: 12px;
	align-items: center;
	height: 100%;
	font-weight: 500;
	padding: 14px 8px;
	font-family: 'Roboto', sans-serif;

	&.off {
		color: black;
	}
}
