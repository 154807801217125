@import "src/styles/colors";
@import "src/styles/typography";
@import "src/styles/forms";

.style-selector {
	$self: &;
	display: flex;
	align-items: center;
	margin-bottom: 30px;

	&_field {
		@extend .text-default;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 24px;

		&_label {
			text-align: center;
			margin-bottom: 4px;
		}

		&_input {
			width: 54px;
		}

		&.item-type {
			margin-bottom: 4px;
		}

		& > input[type=color] {
			height: 27px;
			background-color: white;
			border: 1px solid $tabContentBorderColour;
		}

		& > select {
			@extend .form-field;
			@extend .text-small;
			padding: 3px;
		}
	}

	&.is-horizontal {
		margin-bottom: 0;

		#{ $self }_field {
			flex-direction: row;
			align-items: center;

			&_label {
				margin-bottom: 0;
				margin-right: 8px;
				font-weight: 700;
			}
		}
	}
}