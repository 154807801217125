@import "src/styles/colors";
@import "src/components/ui/button";

.green {
	@extend .btn;
	$self: &;
	border-color: $green;
	color: $green;
	background-color: white;
	width: 100%;

	&_selected {
		@extend .green;
		color: white;
		background-color: $green;

		&:hover {
			background-color: darken($green, 5);
		}
	}

}

.red {
	@extend .btn;
	$self: &;
	border-color: $red2;
	color: $red2;
	background-color: white;
	width: 100%;

	&_selected {
		@extend .red;
		color: white;
		background-color: $red2;

		&:hover {
			background-color: darken($red2, 5);
		}
	}
}

