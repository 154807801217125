@import "src/styles/colors";

.icon-anchor {
	width: 24px;
	height: 24px;
	display: inline-block;
	border-radius: 4px;

	&:hover {
		background-color: $tabHover;
		cursor: pointer;
	}

	& > svg > path {
		fill: $simproBlue;
	}

	&.is-disabled {
		cursor: not-allowed;

		& > svg > path {
			fill: $lighterText;
		}
	}

	&.is-active {
		background-color: $simproBlue;

		& > svg > path {
			fill: white;
		}

		&:hover {
			background-color: $simproBlue;
			cursor: pointer;
		}
	}
}