.modal-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > * {
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}
}
