@import "src/styles/typography";

.base-image-upload-modal {
	&_files {
		margin-bottom: 20px;
	}

	&_add-button {
		margin-bottom: 12px;
	}

	&_formats {
		@extend .text-default;
		margin-bottom: 30px;
	}

}
