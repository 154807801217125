@import "src/styles/typography";
@import "src/styles/colors";

.create-section-input {
	&_label {
		font-weight: bold;
		margin-bottom: 8px;
	}

	& input {
		width: 100%;
		height: 32px;
	}

	&_select {
		@extend .text-default;
		border: 1px solid $tabContentBorderColour;
		width: 100%;
	}

	&_create-new-section-label-container {
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
	}
}
