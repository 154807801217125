.versions-modal {
	flex: 1 1;
	min-height: 0;

	&_content {
		margin: 20px;
		flex: 1 1;
		min-height: 0;
		display: flex;
		flex-direction: column;

		&_table-container {
			overflow: auto;
			flex: 1 1;
			min-height: 0;
			margin-bottom: 20px;

			table {
				margin: 0;

				td.subversion {
					padding-left: 20px;
				}

				thead tr th {
					position: sticky;
					top: 0;
					z-index: 1;
					background-color: white;
				}
			}
		}
	}
}