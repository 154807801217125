@import "src/styles/colors";

.attachment-table-container {
	height: 250px;

	tr {
		cursor: pointer;

		&.active {
			background-color: $focusColour;
		}
	}
}