@import "src/styles/colors";
@import "src/styles/typography";

.accordion-bar {
	display: flex;
	align-items: center;
	cursor: pointer;

	& > * {
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}

	&_label {
		@extend .heading2;
		font-weight: 500;
	}

	& > svg:last-child {
		transform-origin: center center;
		transform: rotate(-90deg);
		transition: transform 0.25s ease;
	}

	&.is-open {
		& > svg:last-child {
			transform: rotate(0);
		}
	}
}
