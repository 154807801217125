@import "src/styles/colors";

.accordion {
	width: 100%;
	height: 40px;
	background-color: $midDarkGrey;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px 10px 10px;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	margin-bottom: 1px;

	&_icon-container {
		margin-right: 10px;
		display: flex;

		svg {
			height: 17px;
			width: 17px;
		}
	}

	&_spacer {
		flex: 1;
	}
}
