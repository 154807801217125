@import "src/styles/colors";

.project-summary-wrapper-modal {
	padding: 0;
	font-size: 14px;
	width: 90%;
	min-width: 90%;
	max-width: 90%;
	height: 90%;
	min-height: 80%;
	max-height: 80%;

	&_header {
		border-bottom: 1px solid $midDarkGrey;
		margin: 20px 20px 0 20px;
		padding-bottom: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&_message-text {
		}

		&_button-container {
			display: flex;
			align-items: center;

			&_icon-download {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
				width: 27px;
				background-color: $midGray;
				border-radius: 4px;
			}
		}
	}

	&_content {
		display: flex;
		flex: 1 1;
		min-height: 0;

		&_sections {
			min-width: 350px;
			border-right: 1px solid $midDarkGrey;
			padding-left: 15px;


			&_header {
				height: 30px;
				font-size: 18px;
				font-weight: bold;
				margin: 18px 30px 10px;
				border-bottom: 1px solid $midDarkGrey;

				input[type=checkbox] {
					margin-right: 12px;
				}
			}

			&_section {
				padding-left: 28px;

				&_cost-centers {
					display: flex;
					align-items: center;
					padding: 4px 20px 10px;

					input[type=checkbox] {
						margin-right: 12px;
					}

				}
			}
		}

		&_materials {
			//min-width: 750px;
			display: flex;
			flex-direction: column;
			flex: 1 1;
			padding: 20px;

			&_title {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 20px;
			}

			&_tree {
				overflow: auto;
				flex: 1 1;
			}
		}
	}
}