.search-bar-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	> * {
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
		}
	}
}