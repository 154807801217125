@import "src/styles/typography";
@import "src/styles/colors";

.breadcrumbs {
	flex: 1 1;

	@extend .text-default;
	font-weight: 500;
	font-size: 12px;
	width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.are-disabled {
		color: $highlightPanelColour;
	}

	&_item {
		& a {
			@extend .link;
		}

		&::after {
			content: "/";
			margin: 0 4px;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}