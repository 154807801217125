@import "src/styles/typography";

.project-plan-summary-modal {
	width: 90%;
	max-width: unset;

	&_content {
		padding: 20px;
	}
}
