.plan-template-rename-modal {
	min-width: 600px;
	min-height: 0;

	&_content {
		margin: 20px 20px;

		&_input-container {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			&_input-label {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 5px;
			}

			&_text-input {
				width: 100%;
			}
		}
	}
}