@import "src/styles/typography";
@import "src/styles/colors";

.create-drawing-modal {
	margin-bottom: 20px;

	&_field {
		@extend .text-default;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 20px;

		&_label {
			font-weight: bold;
			margin-bottom: 8px;
		}

		& input {
			width: 100%;
			height: 32px;
		}
	}
}