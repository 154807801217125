@import "src/styles/typography";
@import "src/styles/colors";

div.set-scale-modal {
	&-content {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;

		&_divider {
			border-left: 1px solid $midDarkGrey;
			margin: 0 20px;
		}

		&_section {
			flex: 1 auto;

			&_field {
				@extend .text-default;
				display: flex;
				flex-direction: column;
				justify-content: center;

				&_label {
					font-weight: bold;
					margin-bottom: 8px;
				}
			}

			&_row {
				display: flex;
				align-items: center;

				strong {
					margin-right: 4px;
				}

				input {
					width: 80px;
					margin: 0 10px;
				}
			}

			.center {
				display: flex;
				justify-content: center;
			}

			&_select {
				height: 27px;
				width: unset;
				flex: 1 auto;
			}
		}
	}

	label {
		display: flex;
		align-items: center;
	}
}