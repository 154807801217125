.navigation-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	&_actions {
		display: flex;

		& > * + * {
			margin-left: 8px;
		}
	}
}