@import "src/styles/colors";

.set-scale-modal {
	&_modal-img {
		width: 50%;
		margin: 15px auto;
		box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
	}

	&_anim {
		border: 1px solid $tabContentBorderColour;
		width: 398px;
		margin: 15px auto;
	}
}