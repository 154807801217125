@import "src/styles/colors";
@import "src/styles/typography";

.len-ctx {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&.hidden {
		display: none;
	}

	&_menu {
		// Update constants menu item width and height after styles changes.
		width: 150px;
		background: white;
		position: absolute;
		display: flex;
		flex-direction: column;
		border: 1px solid $midDarkGrey;
		box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;

		&_list {
			list-style: none;
			padding: 0;
			margin: 0;

			&_item {
				@extend .text-default;
				align-items: center;
				justify-content: center;
				padding: 10px 20px;
				border: none;
				margin: 0;
				transition: background-color 0.25s ease;
				border-bottom: 1px solid $tabContentBorderColour;

				&:last-child {
					border-bottom: none;
				}

				&:hover {
					background-color: darken($tableHover, 5);
				}
			}
		}
	}
}