@import "src/styles/colors";

.create-cost-center-field {

	&_select {
		margin-bottom: 20px;
		border: 1px solid $tabContentBorderColour;
		width: 100%;
	}

	&_text-input {
		width: 100%;
		height: 32px;
	}

	&_label {
		font-weight: bold;
		margin-bottom: 8px;
	}
}