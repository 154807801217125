$simproBlue: #1976D2;
$lightSimproBlue: #229BFF;
$veryLightBlue: #DAE9F8;

$lightBlue: $lightSimproBlue;
$lighterBlue: #91bce7;
$midBlue: #1568b1;
$darkBlue: #0E527F;

$midGray: #DCDEDF;
$midGray2: #c0c0c0;
$lightGray: #F0F1F2;
$green: #41B555;
$red: #ec2b2b;
$red2: #DE104F;
$error: $red;
$yellow: #FEED78;
$darkYellow: #FAAF3C;
// Text colors
$simproErrorPink: #fac0d2;

$baseText: #262626;
$labelsText: #4c4c4c;
$lighterText: #82888c;
$midDarkGrey: #E2E2E2;

$bodyTextColour: #232A2F;
$focusColour: #DDEFFC;
$tabContentBorderColour: #ccc;
$tabHover: #F7F8F9;
$tableHover: #F1F1F1;
$highlightPanelColour: #CCCCCC;

$defaultFontFamily: "Roboto", sans-serif