@import "src/styles/typography";

.imperial-length-input {

	&_label {
		@extend .text-default;
		font-weight: 700;
	}

	&_container {
		display: flex;

		& .number-input > input {
			width: 100px;
			margin-bottom: 0;
		}

		& .number-input > span {
			width: 20px;
			display: inline-block;
		}
	}

	&_spacer {
		width: 15px;
	}
}
