@import "src/styles/mixins";
@import "src/styles/typography";
@import "src/styles/table";
@import "src/styles/forms";

.material-modal {
	width: 1000px;
	height: 100vh;
	max-height: 80vh;
	padding: 15px 30px;

	&_container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&_tabs {
			margin-bottom: 20px;
		}

		&_content {
			flex: 1 auto;
			min-height: 0;
			display: flex;
			flex-direction: column;

			select {
				@extend .form-field;
				@extend .text-small;
				padding: 3px;
				height: 27px;
			}

			.table-overlay {
				flex: 1 auto;
				min-height: 0;
				display: flex;
				flex-direction: column;
			}

			&_breadcrumbs {
				margin-bottom: 10px;
			}

			&_fav-switch {
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				flex-basis: 35px;

				&_label {
					@extend .text-default;
					margin-left: 10px;
				}
			}

			&_table-container {
				overflow: auto;

				&.groups {
					flex: 4 4 0;
					margin-bottom: 10px;
				}

				&.items {
					flex: 6 6 0;
				}

				thead tr th {
					position: sticky;
					top: 0;
					z-index: 1;
					background-color: white;
				}

				td > select {
					margin-top: 22px;
					width: 80px;
				}

				.style-selector {
					margin-bottom: 0;

					&_field {
						min-width: 55px;
						align-items: flex-start;
					}
				}

				.imperial-length-input_container {
					flex-direction: column;
				}

				.number-input {
					@extend .text-default;
					width: 150px;

					input {
						margin-bottom: 0;
						margin-top: 4px;
						padding: 5px;
					}

					&_error {
						background-color: $simproErrorPink;
						border-color: $red;
					}
				}
			}
		}

		&_footer {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.style-selector {
				margin: 0;
			}
		}
	}
}