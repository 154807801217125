@import "src/styles/colors";

.plan-upload-scale-dialog {
	position: absolute;
	width: 400px;
	top: 0;
	left: 0;
	padding: 8px 0 0 8px;
	z-index: 1;

	&_card {
		background-color: $lightGray;
		border: 1px solid $darkYellow;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 3px;

		&_header {
			padding: 8px;
			border-bottom: 1px solid $midDarkGrey;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;

			&_title {
				font-weight: 500;
			}

			&_warning {
				font-weight: 400;
				border-radius: 3px;
				background-color: $darkYellow;
				padding: 4px;

				svg {
					margin-right: 4px;
					position: relative;
					top: 2px;
				}
			}
		}

		&_content {
			padding: 8px;
			display: flex;

			.label {
				color: $lighterText;
				font-weight: 500;
				font-size: 12px;
			}

			&_ratio {
				flex: 1 1;
				min-width: 0;
				border-right: 1px solid $midDarkGrey;
				padding: 12px 0;

				&_row {
					padding-top: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 4px;

					&_select {
						height: 27px;
						width: unset;
						flex: 1 auto;
						background-color: white;
					}

					input {
						width: 80px;
					}
				}
			}

			&_drawing {
				flex: 1 1;
				min-width: 0;
				padding: 12px;

				&_row {
					padding-top: 8px;
					display: flex;
					justify-content: center;
					align-items: center;

					.number-input input {
						margin: 0;
						padding: 5px;
					}
				}
			}
		}

		&_footer {
			padding: 0 8px 8px 8px;
			display: flex;
			justify-content: flex-end;

			& * + * {
				margin-left: 10px;
			}
		}
	}
}