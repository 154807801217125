.editor-panel-tab-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	& .btnBlue {
		width: 50%;
		margin: 0 10px 10px 10px;
	}
}