.plan-page-delete-modal {
	min-width: 600px;
	min-height: 0;

	&_content {
		margin: 20px 20px;

		&_message {
			font-size: 14px;
			margin-bottom: 25px;
		}
	}
}