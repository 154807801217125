@import "src/styles/typography";
@import "src/styles/colors";

.error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&_heading {
		@extend .heading1;
		color: $error;
		margin-bottom: 20px;
	}

	&_cookie-modal {
		min-height: unset;
	}
}