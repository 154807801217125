@import "src/styles/colors";
@import "src/styles/typography";

.image-preview-modal {
	&_content {
		position: relative;
		max-height: 50vh;
		min-height: 340px;
		margin-bottom: 20px;
		width: 100%;

		& .spinner {
			z-index: 2;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}

		&_error {
			z-index: 3;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $error;
			background-color: white;
			@extend .heading2;
		}

		& > img {
			display: none;
			height: 0;
			width: 0;
		}
	}
}