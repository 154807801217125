@import "src/styles/typography";
@import "src/styles/mixins";

.table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	margin-bottom: 20px;

	thead {
		tr {
			padding: 0 6px;
			border-bottom: 2px solid $lighterText;

			th {
				@extend .text-default;
				color: $lighterText;
				text-align: left;
				font-size: 12px;
				font-weight: 500;
				padding: 8px;
				vertical-align: bottom;
			}
		}
	}

	tbody {
		tr {
			padding: 0 6px;
			border-bottom: 1px solid $midDarkGrey;

			&.is-checked {
				background-color: $focusColour;

				&:hover {
					background-color: $focusColour;
				}
			}

			&:hover {
				background-color: $tableHover;
			}

			&.is-clickable {
				cursor: pointer;
			}

			td {
				@extend .text-default;
				font-size: 12px;
				font-weight: 400;
				padding: 8px;
				line-height: 18px;
				@include media(xl) {
					padding: 6px;
				}

				&.break-word {
					word-break: break-word;
				}
			}
		}
	}
}

.scrollable {
	overflow-y: auto;
	height: 160px;
	margin-bottom: 20px;

	th {
		background: white;
	}

	thead th {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}