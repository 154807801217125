@import "src/styles/typography";

.tree-sections {
	flex: 1;

	&_label {
		@extend .text-default;
		font-size: 18px;
		font-weight: 500;
		display: flex;
		align-items: center;
		height: 38px;
	}
}
