@import "src/styles/colors";
@import "src/styles/typography";

.modal-with-tabs {
	padding: 0;

	&_tabs {
		display: flex;

		& > button {
			@extend .text-default;
			border: none;
			margin: 0;
			background-color: $midGray;
			flex: 1;
			padding: 10px;
			color: $lighterText;
			cursor: pointer;

			&:hover {
				background-color: darken($midGray, 5);
			}

			&.active {
				background-color: white;
				color: $simproBlue;
				cursor: unset;
			}

			&.disabled {
				cursor: not-allowed;
			}
		}
	}

	&_content {
		padding: 30px;

		&_children {
			margin-bottom: 20px;
		}
	}
}