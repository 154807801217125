@import "src/styles/typography";

.resize-stage-modal {
	&_content {
		@extend .text-default;

		&_title {
			font-weight: 700;
			margin-bottom: 10px;
		}

		&_bold {
			font-weight: 700;
		}

		&_resize-form {
			&_title {
				font-weight: 700;
				margin-bottom: 10px;
			}

			&_field {
				margin-bottom: 10px;

				& > label {
					font-weight: normal;
					display: inline-block;
					text-align: right;
					width: 125px;

					& > input {
						width: 70px;
					}
				}

				& > span {
					margin-left: 5px;
					font-weight: 700;
				}
			}
		}
	}
}