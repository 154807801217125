@import "src/styles/mixins";
@import "src/styles/typography";

.one-off-modal {
	padding: 20px;

	&_name-input {
		width: 100%;
	}

	&_styles {
		display: flex;
		justify-content: space-between;
		margin: 15px 0;
		height: 60px;

		&_container {
			display: inline-flex;
		}

		.style-selector {
			margin: 6px 0 10px;
		}

		input, select {
			height: 27px;
		}
	}
}