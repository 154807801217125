@import "src/styles/colors";

.text-button {
	margin: 0;
	padding: 0 4px;
	background: none;
	border: none;
	cursor: pointer;
	color: $lightSimproBlue;
	text-decoration: underline;
	font-weight: bold;
	font-size: 16px;
}