@import "src/styles/colors";
@import "src/styles/forms";
@import "src/styles/mixins";
@import "src/styles/typography";

.search-bar {
	$self: &;
	border-radius: 3px;
	margin: 0;
	background-color: #fff;
	overflow: hidden;
	height: 30px;
	float: left;
	width: 500px;
	border: 1px solid #ccc;
	position: relative;

	&_search-icon {
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: 2;
		display: block;
		cursor: text;
	}

	&_placeholder-text {
		@extend .text-default;
		color: $tabContentBorderColour;
		position: absolute;
		top: 6px;
		left: 25px;
		z-index: 2;
		display: block;
	}

	&_input {
		@extend .form-field;
		width: calc(100% - 80px);
		padding-right: 32px;
		position: absolute;
		height: 28px;
		margin-right: 80px;
		z-index: 1;
		border: 1px solid transparent;

		&:focus + #{$self}_placeholder {
			display: none;
		}
	}

	&_submit {
		position: absolute;
		top: 0;
		right: 0;
		visibility: visible;
		height: 28px;
		width: 80px;
		text-align: center;
		background: #fff;
		cursor: pointer;
		border: 0;
		border-left: 1px solid #ccc;
		font-size: 14px;
		color: $baseText;
		border-radius: 0 3px 3px 0;
		outline: none;
		font-family: $defaultFontFamily;

		&:hover {
			color: $simproBlue;
		}

		&:active {
			padding-top: 2px;
		}

		&.is-disabled {
			&:hover {
				color: $baseText;
			}
		}
	}

	&_clear {
		position: absolute;
		right: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 18px;
		top: 5px;
		z-index: 2;
		cursor: pointer;

		&:hover {

			> svg * {
				fill: $lightSimproBlue;
			}
		}
	}
}