@import "src/styles/colors";
@import "src/styles/typography";

.side-panel {
	$self: &;
	display: flex;

	&_wrapper {
		width: 360px;
		overflow-x: hidden;
		transition: width 0.25s ease;
		background-color: #F7F8F9;
	}

	&_toggle {
		background: $veryLightBlue;
		stroke: #1976D2;
		stroke-width: 2px;
		width: 20px;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-left: 1px solid $midDarkGrey;
		transition: background 0.35s ease;

		&:hover {
			background: darken($veryLightBlue, 6);
		}
	}

	&.is-expanded {
		#{ $self }_wrapper {
			width: 0;
		}

		#{ $self }_toggle {
			svg {
				transform: rotateZ(180deg);
			}
		}
	}
}