@import "src/styles/colors";

.symbol-recognition-selection-panel {
	margin-left: 24px;
	margin-right: 24px;

	&_header {
		margin-top: 26px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&_title {
			font-weight: 500;
			font-size: 18px;
		}

		&_icon-container {
			cursor: pointer;
			display: flex;
			align-items: center;
		}
	}

	&_description {
		margin-top: 24px;
		padding-bottom: 12px;
		font-weight: 400;
		font-size: 12px;
	}

	&_select-section {
		font-weight: 500;
		font-size: 12px;
		display: flex;
		flex-direction: column;

		&_preview-container {
			margin-top: 8px;
			height: 280px;
		}
	}

	&_buttons {
		margin-top: 24px;
		padding-bottom: 24px;
		display: flex;
		justify-content: flex-end;

		& > * + * {
			margin-left: 12px;
		}
	}
}
