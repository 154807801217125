.project-drawing-title {
	margin-bottom: 20px;
	display: flex;
	padding-right: 150px;
	align-items: center;

	&_name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 700;
		text-transform: uppercase;
		margin-right: 4px;
	}

	&_version {
		white-space: nowrap;
		margin-left: 4px;
	}

	button {
		margin-left: 10px;
	}
}