@import "src/styles/typography";
@import "src/styles/forms";

.modal-content {
	margin-bottom: 10px;

	&_message {
		margin-bottom: 30px;
		flex: 1;
	}

	&_field {
		@extend .text-default;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;

		&_container {
			@extend .form-field;
			height: 28px;
			// Same as disabled input
			color: rgb(84, 84, 84)
		}

		&_with-button-container {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			& > div {
				margin-right: 20px;
				width: 100%;
			}

			& > button {
				margin-top: 3px;
			}
		}

		&_label {
			font-weight: bold;
			margin-bottom: 8px;
		}

		&_select-container {
			margin-bottom: 30px;
		}

		&_select {
			@extend .text-default;
			border: 1px solid $tabContentBorderColour;
			width: 100%;
		}

		& input {
			width: 100%;
			height: 32px;
		}
	}
}
