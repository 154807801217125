@import "src/styles/colors";

.form-field {
	outline: none;
	padding: 5px;
	border-radius: 3px;
	margin: 0;
	background-color: #fff;
	border: 1px solid #ccc;

	&:focus {
		background-color: $focusColour;
		border-bottom: 1px solid #0E527F;
	}
}