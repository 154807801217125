@import "src/styles/typography";
@import "src/styles/colors";

.plan-templates {
	$self: &;
	padding: 20px 20px 20px 0;
	flex: 3 3;
	min-height: 0;
	min-width: 0;

	&_header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 25px;

		&_label {
			@extend .text-default;
			font-size: 18px;
			font-weight: bold;
		}
	}

	&_content {
		display: flex;
		flex-direction: column;

		&_item {
			@extend .text-default;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: 38px;
			border-bottom: 1px solid $midDarkGrey;

			&_filename-container {
				display: flex;
				align-items: center;

				&_icon {
					width: 35px;
					padding-right: 10px;
				}
			}

			&:hover {
				background-color: $tableHover;
			}

			&.no-records {
				justify-content: center;
				font-weight: 500;
			}

			&.previewable {
				cursor: pointer;
			}
		}
	}
}