@import "src/styles/typography";
@import "src/styles/colors";

.project-tree {
	padding: 20px 0 20px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	border-left: 1px solid lightgray;
	flex: 7 7;
	min-height: 0;
	min-width: 0;

	&_header {
		display: flex;
		padding-bottom: 15px;

		&_title {
			margin-left: 5px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	&_button-container {
		display: flex;

		& > * {
			margin-left: 15px;
		}
	}
}