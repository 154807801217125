@import "src/styles/colors";

.symbol-recognition-panel {
	padding-left: 0;
	width: 360px;
	height: fit-content;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

	&_top-bar {
		height: 15px;
		background-color: $lightSimproBlue;
	}
}