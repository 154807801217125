@import "src/styles/colors";

.plan-tree-page {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	&_line-vertical {
		left: 8px;
		border-left: 2px solid $simproBlue;
		height: 100%;
		position: absolute;

		&.first-item {
			height: calc(100% + 10px);
		}

		&.last-item {
			height: calc(50% + 5px);
			top: -5px;
		}
	}

	&_container {
		width: 100%;

		&_line-horizontal {
			left: 8px;
			border-top: 2px solid $simproBlue;
			width: 15px;
			position: absolute;
			top: 50%
		}

		&_content {
			min-height: 30px;
			height: 100%;
			flex: 1;
			cursor: pointer;
			margin-top: 5px;
			margin-left: 23px;
			padding-right: 10px;
			padding-left: 7px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&_title {
				flex: 1 1;
				min-width: 0;
				height: 100%;
				display: flex;
				align-items: center;

				&_text {
					word-break: break-all;
				}

				&_icon {
					display: flex;
					margin-right: 5px;
				}

				& * + * {
					margin-left: 10px;
				}

			}

			&:hover {
				background-color: $focusColour;
				color: $lightBlue;
			}

			&.selected {
				background-color: $simproBlue;
				color: white;
			}
		}
	}
}
