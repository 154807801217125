@import "src/styles/typography";
@import "src/styles/colors";
@import "src/styles/forms";

.custom-select {
	$self: &;

	@extend .text-default;
	padding: 7px;
	border-radius: 4px;
	border: 1px solid $tabContentBorderColour;
	cursor: pointer;
	width: 500px;
	height: 32px;

	&.active {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&.no-value {
			background-color: $focusColour;
		}
	}

	&_label {
		height: 100%;
		align-items: center;
		justify-content: space-between;
		display: flex;
		margin-right: auto;

		> span {
			flex: 1;
			min-width: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&_items {
		@extend .text-default;
		cursor: pointer;
		background-color: white;
		width: calc(100% + 2px);
		border: 1px solid $tabContentBorderColour;
		max-height: 30vh;
		overflow: auto;

		&_item {
			padding: 7px;
			align-items: center;
			justify-content: space-between;
			display: flex;
			margin-right: auto;

			&:hover {
				background-color: $tableHover;
			}

			&.selected {
				background-color: $focusColour;
			}

			&_additional {
				cursor: default;
			}
		}
	}
}