.project-plan-drawing {
	display: flex;
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;

	&_overlay {
		z-index: 1000;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.90);
		visibility: hidden;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		opacity: 0;
	}

	&.has-overlay {
		.project-plan-drawing_overlay {
			visibility: visible;
			opacity: 1;
			cursor: not-allowed;
		}
	}

	&.fullscreen {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: white;
	}
}