@import "src/styles/typography";

.image-upload-modal {
	&_field {
		@extend .text-default;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&_label {
			font-weight: bold;
			margin-bottom: 8px;
		}

		&_select {
			@extend .text-default;
			margin-bottom: 20px;
			border: 1px solid $tabContentBorderColour;
			width: 100%;
		}

		& input {
			width: 100%;
			height: 32px;
			margin-bottom: 20px;
		}
	}
}