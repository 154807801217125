@import "src/styles/typography";
@import "src/styles/colors";

.full-page-message {
	@extend .heading2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: $bodyTextColour;
}