@import "src/styles/typography";
@import "src/styles/colors";

.tree-drawing {
	display: flex;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
	border-bottom: 1px solid lightgray;
	padding: 4px 0;

	& > * + * {
		margin-left: 8px;
	}

	&_checkbox {
		flex: none;
		margin-right: 8px;
	}

	&_clickable-container {
		cursor: pointer;
		width: 100%;
		display: flex;
		align-items: center;
		min-width: 0;

		&_takeoff-icon {
			width: 28px;
			height: 28px;
		}

		&_name {
			margin-left: 4px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&_version-container {
			margin-left: 8px;
			padding-top: 4px;
			color: $lighterText;
			font-size: 12px;
			font-style: italic;
			white-space: nowrap;

			&_item {
				padding-left: 5px;
			}

			&_version {
				font-style: normal;
				font-weight: bold;
				margin-right: 4px;

				&.completed {
					color: $green;
				}
			}
		}
	}
}
