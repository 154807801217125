@import "src/styles/forms";
@import "src/styles/typography";

.text-input {
	@extend .text-default;
	font-weight: 700;

	& input {
		@extend .form-field;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}
	}

	&.is-labeled {
		margin-left: 12px;

		& input {
			margin-left: 12px;
		}
	}
}