$background-color: #f7f8f9;

.editor-header-visibility-dropdown {
	background-color: $background-color;
	width: 150px;
	border-radius: 4px;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

	&_item {
		padding: 2px 2px 2px 8px;
		display: flex;
		align-items: center;

		& > svg {
			pointer-events: none;
		}

		&:hover {
			background-color: darken($background-color, 5);
			cursor: pointer;
		}

		span {
			margin-left: 8px;
		}

		& > div {
			margin-left: 6px;
		}
	}
}