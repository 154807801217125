@import "src/styles/typography";
@import "src/styles/colors";

.tree-cost-center {
	$self: &;

	&_header {
		height: 38px;
		display: flex;
		border-bottom: 1px solid $midDarkGrey;
		align-items: center;
		position: relative;

		&_label-container {
			font-size: 12px;

			&_label {
				font-size: 14px;
				font-weight: bold;

				&_deleted {
					color: red;
				}
			}
		}

		&:hover {
			background-color: $tableHover;
		}

		& > input[type=checkbox] {
			margin-right: 12px;
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		height: 0;
		visibility: hidden;
		overflow: hidden;
	}
}
