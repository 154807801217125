@import "src/styles/forms";
@import "src/styles/colors";

.symbol-icon-selector {
	$self: &;
	@extend .form-field;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 27px;
	width: 45px;
	cursor: pointer;

	&_icon {
		width: 24px;
		height: 24px;
	}


	&_content {
		width: 300px;
		min-height: 250px;
		border: 1px solid #ccc;
		border-radius: 3px;
		background-color: white;

		&_header-grid {
			display: grid;
			grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
			column-gap: 8px;
			row-gap: 8px;
			padding: 8px;
		}

		&_grid-container {
			height: 200px;
		}

		&_grid {
			width: 100%;
			max-height: 200px;
			overflow-y: auto;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			column-gap: 4px;
			row-gap: 4px;
			padding: 4px;
			margin-bottom: 4px;

			&_item {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 50px;
				width: 50px;
				border: 1px solid $tabContentBorderColour;

				&:hover {
					background-color: $tableHover;
				}

				& img {
					height: 100%;
					width: 100%;
					filter: brightness(0);

					* {
						fill: red;
					}
				}
			}
		}

		.custom-select {
			width: 100%;
		}
	}

	&.is-expanded {
		background-color: $focusColour;
		border-bottom: 1px solid #0E527F;
	}
}