@import "src/styles/typography";
@import "src/styles/colors";

.tree-cost-center-summary {
	$self: &;

	&_header {
		height: 38px;
		display: flex;
		border-bottom: 1px solid $midDarkGrey;
		align-items: center;
		position: relative;

		&_bar {
			display: flex;
			align-items: center;
			cursor: pointer;

			&_label {
				@extend .text-default;
				font-weight: 500;
			}

			input {
				margin: 0 12px;
			}
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		height: 0;
		visibility: hidden;
		overflow: hidden;

		&_no-records {
			@extend .text-default;
			display: flex;
			align-items: center;
			height: 38px;
			border-bottom: 1px solid $midDarkGrey;
			justify-content: center;
			font-weight: 500;
		}
	}

	&.is-open {
		#{ $self }_content {
			height: auto;
			visibility: visible;
			overflow: visible;
		}
	}
}

