@import "src/styles/colors";

.dropdown-arrow {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	padding: 0 8px;
	cursor: pointer;

	&_icon {
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
	}
}

.arrow-white-container {
	@extend .dropdown-arrow;
	border-left: 1px solid white;

	&_icon {
		@extend .dropdown-arrow_icon;
		border-top: 6px solid white;
	}
}

.arrow-blue-container {
	@extend .dropdown-arrow;
	border-left: 1px solid $simproBlue;

	&_icon {
		@extend .dropdown-arrow_icon;
		border-top: 6px solid $simproBlue;
	}
}