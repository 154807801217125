@import "src/styles/colors";

.icon-visible-button {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	width: 25px;
	height: 25px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.5);
	}
}