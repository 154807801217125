@import "src/styles/typography";
@import "src/styles/colors";

.table-header-cell {
	color: $lighterText;

	&.is-active {
		font-weight: 500;
		color: $baseText;
	}

	&:hover {
		color: $simproBlue;
	}

	&.is-clickable {
		& > span {
			cursor: pointer;
		}
	}

	&.action {
		text-align: center;
	}
}
