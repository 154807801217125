@import "src/styles/typography";

.export-status-modal {

	table {
		margin: 0 auto 20px;
		text-align: center;
		width: 100%;

		& tr {
			border-bottom: 1px solid $midDarkGrey;

			& > *:first-of-type {
				text-align: left;
			}

			& > td {
				@extend .text-default;
				height: 38px;
			}
		}
	}

	.modal-buttons {
		height: 32px;
	}
}
