@import "src/styles/colors";
@import "src/styles/typography";

.upload-status {
	display: flex;
	align-items: center;
	@extend .text-default;

	& > svg {
		margin-right: 8px;
	}

	&.success {
		color: $green;
	}

	&.error {
		color: $error;
	}
}