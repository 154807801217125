@import "src/styles/typography";
@import "src/styles/colors";

.modal-with-header {
	max-width: 1000px;
	max-height: 90%;
	min-width: 600px;
	width: auto;
	padding: 0;

	&_header {
		height: 40px;
		background-color: $lightGray;
		color: $simproBlue;
		font-weight: bold;
		display: flex;
		flex-shrink: 0;
		justify-content: space-between;
		align-items: center;
		padding: 0 15px;

		&_title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		span {
			cursor: pointer;
		}
	}
}
