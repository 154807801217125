@import "src/styles/typography";
@import "src/styles/colors";
@import "src/styles/mixins";

.editor-header {
	display: flex;
	flex-direction: column;

	&_main {
		padding: 16px 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $midDarkGrey;
	}

	&_options {
		height: 64px;
		padding: 16px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 1px solid $midDarkGrey;
	}

	@include media(smallest) {
		.editor-header-spacer {
			display: none;
		}
	}
}