@import "src/styles/colors";

.text-default {
	font-size: 12px;
	font-weight: 400;
	color: $baseText;
}

.heading1 {
	font-size: 18px;
	font-weight: 500;
}

.heading2 {
	font-size: 15px;
	font-weight: 500;
}

.heading3 {
}

.text-small {
	font-size: 11px;
}

.text-button {
}

.link {
	@extend .text-default;
	cursor: pointer;
	color: $simproBlue;
	text-decoration: none;
	font-weight: 500;
	font-size: 12px;

	&:hover {
		text-decoration: underline;
		color: #024bb3;
	}
}