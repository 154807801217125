@import "src/styles/colors";

.tabs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid $midDarkGrey;
	width: 100%;

	&.has-sub-tabs {
		border-bottom: none;
	}
}

.sub-tabs {
	@extend .tabs;
	border-bottom: none;
	background-color: $midDarkGrey;
}