@import "src/styles/typography";

.anchor-grid {
	&_label {
		@extend .text-default;
		font-weight: 700;
		margin-bottom: 10px;
	}

	&_grid {
		&_row {
			height: 30px;

			& > div {
				margin: 3px;
			}
		}
	}
}