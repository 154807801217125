@import "src/styles/typography";

.project-export-confirmation-modal {
	width: 500px;
	&_content {
		@extend .text-default;
	}

	.modal-buttons {
		margin-top: 30px;
	}
}
