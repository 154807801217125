@import "src/styles/colors";

.tree-section-summary {
	$self: &;

	&_header {
		display: flex;
		align-items: center;
		height: 38px;
		border-bottom: 1px solid $midDarkGrey;

		input {
			margin: 0 12px;
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		height: 0;
		visibility: hidden;
		overflow: hidden;
	}

	&.is-open {
		#{ $self }_content {
			height: auto;
			visibility: visible;
			overflow: visible;
		}
	}

	&:first-child {
		#{ $self }_header {
			border-top: 1px solid $midDarkGrey;
		}
	}
}
