.plan-upload-confirm-modal {
	min-width: 400px;
	min-height: 0;

	&_content {
		margin: 20px 20px;
		font-size: 14px;

		&_line1 {
			margin-bottom: 20px;
		}

		&_line2 {
			margin-bottom: 10px;
		}
	}
}