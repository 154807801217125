.color-picker {
	&_swatch {
		padding: 5px;
		background: white;
		border-radius: 2px;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
		cursor: pointer;

		&_color {
			width: 36px;
			height: 16px;
			border-radius: 2px;
		}

		&_transparent {
			width: 36px;
			height: 16px;
			border-radius: 2px;
			border: 1px solid black;
		}
	}
}
