@import "src/components/ui/button";

.base-image-options {
	&_add-label {
		cursor: pointer;
		position: relative;
		display: inline-block;
	}

	&_add-input {
		cursor: pointer;
		outline: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		overflow: hidden;
		opacity: 0;
	}

	&_add-button {
		@extend .btn;
		position: initial;
		font-weight: 700;
		text-transform: unset;
		background-color: transparent;

		> svg {
			margin-right: 8px;
		}

		&:hover {
			background-color: darken(white, 5);
		}

		&:focus {
			background-color: darken(white, 5);
		}
	}
}