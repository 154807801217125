@import "src/styles/colors";

.icon-unselectable {
	align-items: center;
	justify-content: center;
	display: flex;
	height: 32px;
	width: 32px;

	&:hover {
		background-color: $tabHover;
		cursor: pointer;
	}
}