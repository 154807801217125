@import "src/styles/colors";

.editor {
	border-left: 1px solid $midDarkGrey;
	width: 100%;
	display: grid;
	overflow: auto;
	overflow-y: hidden;
	grid-template-rows: auto 1fr;

	&_host {
		flex: 1 auto;
		position: relative;
		background-color: $lightGray;
		touch-action: none; //reference https://github.com/bevacqua/dragula/issues/468

		&_scroll-container {
			cursor: default;
			overflow: auto;
			position: absolute;

			&_stage {
				overflow: hidden;
				position: relative;
			}
		}
	}
}